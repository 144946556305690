import { Injectable } from '@angular/core'

import { DashboardInventoryDto, DashboardOverviewDto, FindDashboardDto, MerchantDashboardAnalyticsDto, ProductDto } from '../dto'

import { HttpService } from './http.service'
import { DashboardGraphDto, FindDashboardGraphDto } from '@api/dto/dashboard.dto'

@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findDashboardDto: FindDashboardDto) {
    return await this.httpService.get<DashboardOverviewDto>('/merchant/v1/dashboard/overview', findDashboardDto)
  }

  async findInventory() {
    return await this.httpService.get<DashboardInventoryDto>('/merchant/v1/dashboard/inventory')
  }

  async findSaleProduct({ startDate, endDate }: FindDashboardDto) {
    return await this.httpService.get<ProductDto[]>('/merchant/v1/dashboard/sale-products', { startDate, endDate })
  }

  async findAnalytics({ startDate, endDate }: FindDashboardDto) {
    return await this.httpService.get<MerchantDashboardAnalyticsDto>('/merchant/v1/dashboard/analytics', { startDate, endDate })
  }

  async findGraph(findDashboardGraphDto: FindDashboardGraphDto){
    return await this.httpService.get<DashboardGraphDto>('/merchant/v1/dashboard/graph', findDashboardGraphDto)
  }
}
