import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CancelEbayOrderDto, EbayProductDto, FindEbayProductDto, PageResultDto, ShipEbayOrderDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class EbayService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findProduct(findProductDto: FindEbayProductDto) {
    return await this.httpService.get<PageResultDto<EbayProductDto>>(`/merchant/v1/channel/ebay/products`, findProductDto)
  }

  async findOneProduct(id: string) {
    return await this.httpService.get<EbayProductDto>(`/merchant/v1/channel/ebay/products/${id}`)
  }

  async cancelOrder(cancelOrderDto: CancelEbayOrderDto) {
    return await this.httpService.post<any>(`/merchant/v1/channel/ebay/orders/cancel`, cancelOrderDto)
  }

  async shipOrder(shipChannelOrderDto: ShipEbayOrderDto) {
    return await this.httpService.post<any>(`/merchant/v1/channel/ebay/orders/ship`, shipChannelOrderDto)
  }

  async updateReSync(id: string) {
    return await this.httpService.post<any>(`/merchant/v1/channel/ebay/products/re-sync`, { productId: id })
  }
}
