import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { HttpService } from './http.service'

import { CreateTenantDto, FindTenantDto,  PageResultDto, TenantDto, UpdateTenantDto } from '../dto'

@Injectable()
export class AdminTenantService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findTenantDto: FindTenantDto) {
    return await this.httpService.get<PageResultDto<TenantDto>>(`/admin/v1/tenants`, findTenantDto)
  }

  async findTenant(findTenantDto: FindTenantDto) {
    return await this.httpService.get<PageResultDto<TenantDto>>(`/admin/v1/tenants/featured/tenant`, findTenantDto)
  }

  async create(createTenantDto: CreateTenantDto) {
    return await this.httpService.post<TenantDto>(`/admin/v1/tenants`, createTenantDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TenantDto>(`/admin/v1/tenants/${id}`)
  }

  async update(id: string, updateTenantDto: UpdateTenantDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenants/${id}`, updateTenantDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/tenants/${id}`)
  }

  async export() {
    return await this.httpService.get<any>(`/admin/v1/tenants/export`)
  }

  async updateLogo(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/logo`, { fileId })
  }

  async updateCover(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/cover`, { fileId })
  }

  async updateMobileCover(id: string, fileId: string) {
    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/mobile-cover`, { fileId })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/${id}/disable`)
  }

  async setAdmin(id: string, ownerIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenants/${id}/admins`, { ownerIds })
  }
}
