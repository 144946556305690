import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateFeaturedTenantDto, TenantDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminFeaturedTenantService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<TenantDto[]>(`/admin/v1/tenants/featured`)
  }

  async create(createFeaturedTenantDto: CreateFeaturedTenantDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/tenants/featured`, createFeaturedTenantDto)
  }

  async sortOrder(tenantIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/tenants/featured/sort-order`, { tenantIds })
  }

  async delete(tenantId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/tenants/featured/${tenantId}`)
  }

  async setEnable(tenantId: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/tenants/featured/${tenantId}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/tenants/featured/${tenantId}/disable`)
  }
}
