import { BaseDto } from "./base.dto"
import { DateOfBirthDto } from "./date-of-birth.dto"
import { OrderDto } from "./order.dto"
import { PagingDto } from "./paging.dto"
import { TenantDto } from "./tenant.dto"
import { UserDto } from "./user.dto"

export class CreateTenantVoucherDto {
  // khmerTitle: string: string
  enTitle: string
  amount: number
  minOrder: number
  discountType: string
  startDate?: DateOfBirthDto
  endDate?: DateOfBirthDto
  limitUsage?: number

  static create() {
    return new CreateTenantVoucherDto()
  }
}

export class FindTenantVoucherDto extends PagingDto {
  status?: string
  searchText?: string
}

export class TenantVoucherDto extends BaseDto {
  // khmerTitle: string: string
  enTitle: string
  amount: number
  discountType: string
  endDate: Date
  limitUsage: number
  status: string
  voucherCode: string
  tenant: TenantDto
  
  static create() {
    return new TenantVoucherDto()
  }
}

export class TenantVoucherDetailDto extends BaseDto {
  // khmerTitle: string: string
  enTitle: string
  amount: number
  discountType: string
  startDate: DateOfBirthDto
  endDate: DateOfBirthDto
  minOrder: number
  givenType: string
  status: string
  limitUsage: number
  ownerIds: string[]

  static create() {
    return new TenantVoucherDetailDto()
  }
}

export class TenantVoucherHistoryDto extends BaseDto {
  owner: UserDto
  total: number
  voucherTotal: number
  voucherDiscountAmount: number
  voucherDiscountType: string
  tenantOrder: OrderDto
  tenantVoucher: TenantVoucherDto

  static create() {
    return new TenantVoucherHistoryDto()
  }
}

export enum DiscountStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired'
}