import { BaseDto } from "./base.dto"
import { DateOfBirthDto } from "./date-of-birth.dto"
import { OrderDto } from "./order.dto"
import { PagingDto } from "./paging.dto"
import { TenantDto } from "./tenant.dto"
import { UserDto } from "./user.dto"

export class TenantCouponCodeDto {
  id: string
  limitUsage: number
  usedCount: number
  couponCode: string
  isValid: boolean
}

export class CreateTenantCouponDto {
  limitPerUser: number
  amount: number
  minOrder: number
  discountType: string
  startDate?: DateOfBirthDto
  endDate?: DateOfBirthDto
  limitUsage?: number
  numberOfCode?: number
  couponCode?: string
  codeType: string
  prefix?: string

  static create() {
    return new CreateTenantCouponDto()
  }
}

export class FindTenantCouponDto extends PagingDto {
  status?: string
  searchText?: string
}

export class TenantCouponDto extends BaseDto {
  limitPerUser: number
  amount: number
  discountType: string
  limitUsage: number
  status: string
  couponCodes: TenantCouponCodeDto[]
  numberOfCode: number
  codeType: string
  startDate: DateOfBirthDto
  endDate: DateOfBirthDto
  minOrder: number
  prefix: string
  code: string
  
  static create() {
    return new TenantCouponDto()
  }
}

export class TenantCouponHistoryDto extends BaseDto {
  owner: UserDto
  total: number
  couponTotal: number
  discountAmount: number
  discountType: string
  tenantOrder: OrderDto
  couponCode: string
  tenantCoupon: TenantCouponDto

  static create() {
    return new TenantCouponHistoryDto()
  }
}

export enum DiscountStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired'
}