import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateTrendingSearchDto, CustomerInquiryDto, FindCustomerInquiryDto, FindTrendingSearchDto, PageResultDto, TrendingSearchDto, UpdateTrendingSearchDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminTrendingSearchService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findTrendingSearchDto: FindTrendingSearchDto) {
    return await this.httpService.get<PageResultDto<TrendingSearchDto>>(`/admin/v1/trending-search`, findTrendingSearchDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<TrendingSearchDto>(`/admin/v1/trending-search/${id}`)
  }

  async create(createTrendingSearch: CreateTrendingSearchDto) {
    return await this.httpService.post<TrendingSearchDto>(`/admin/v1/trending-search`, createTrendingSearch)
  }

  async update(id: string, updateTrendingSearch: UpdateTrendingSearchDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/trending-search/${id}`, updateTrendingSearch)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/trending-search/${id}`)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/trending-search/sort-order`, { ids })
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/trending-search/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/trending-search/${id}/disable`)
  }
}
