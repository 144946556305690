import * as _ from 'lodash'

import { FileDto } from './file.dto'
import { MallServicePageDataDto } from './mall-service-page.dto'

import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'

export class FindPageDto extends PagingDto {
  public searchText?: string
}

export class PageDto {
  id: string
  title: TranslationDto
  content: TranslationDto
  slug: string
  data: MallServicePageDataDto
  enabledLinkButton: boolean
  image?: FileDto
  shortDescription?: TranslationDto

  static create() {
    return _.assign<PageDto>({
      id: null,
      title : TranslationDto.create(),
      content : TranslationDto.create(),
      shortDescription: TranslationDto.create(),
      slug : '',
      data: MallServicePageDataDto.create()
    })
  }
}
