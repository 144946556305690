import { Injectable } from '@angular/core'

import { FindOrderDto, OrderDto, PageResultDto } from '../dto'
import { CountStatusDto, ExportOrderDto, OrderDetailDto, OrderRejectDto, ShippingOrderDto } from '../dto/order.dto'
import { HttpService } from './http.service'


@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService
  ) { }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/merchant/v1/orders', findOrderDto)
  }

  async countStatus() {
    return await this.httpService.get<CountStatusDto>('/merchant/v1/orders/count')
  }

  async exportExcel(dateFilterDto: ExportOrderDto) {
    return await this.httpService.get<any>(`/merchant/v1/orders/export`, dateFilterDto)
  }

  async findOne(tenantOrderNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/merchant/v1/orders/${tenantOrderNumber}`)
  }

  async accept(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/accept`)
  }

  // async complete(tenantOrderNumber: string) {
  //   return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/complete`)
  // }

  async shipping(tenantOrderNumber: string, shippingOrderDto: ShippingOrderDto) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/ship`, shippingOrderDto)
  }

  async reject(tenantOrderNumber: string, { reason }: OrderRejectDto) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/reject`, { reason })
  }

  // async readyPickup(tenantOrderNumber: string) {
  //   return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/ready-pickup`)
  // }

  // async addTrackingNumber(tenantOrderNumber: string, trackingNumber: string) {
  //   return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/trackingNumber`, { trackingNumber })
  // }

  // async cancel(tenantOrderNumber: string) {
  //   return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/cancel`)
  // }

  async return(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/return`)
  }

  async rejectReturnRequest(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/reject-return-request`)
  }

  async delivered(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/delivered`)
  }

  async acceptCancelRequest(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/accept-cancel-request`)
  }

  async rejectCancelRequest(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/reject-cancel-request`)
  }
}
