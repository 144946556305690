import { BaseDto } from "./base.dto"
import { CategoryDto } from "./category.dto"
import { PagingDto } from "./paging.dto"

import { ProductDto } from "./product.dto"

export class WalmartProductDto extends BaseDto {
  product: ProductDto
  status: string
  response: any
  sku: string
  channelSku: string
  walmartFeedId: string
  ingestionErrors: any
  validationErrors: any

  static create() {
    return new WalmartProductDto()
  }
}

export class FindWalmartProductDto extends PagingDto {
  searchText?: string
  statuses?: string[]
}

export class CancelWalmartOrderDto {
  reason: string
  orderNumber: string
  // amount: number
  // sku: string
}

export class ShipWalmartOrderItemDto {
  lineNumber: string
  amount: number
  shipDateTime: Date
  carrierName: string
  trackingNumber: string
  // sellerOrderId: string
}

export class ShipWalmartOrderDto {
  // orderLines: ShipWalmartOrderItemDto[]
  // purchaseOrderId: string

  shipDateTime: Date
  orderNumber: string
  // sku: string
  trackingNumber: string
  carrierName: string
  // amount: number
}

export class RefundWalmartOrderDto {
  reason: string
  orderNumber: string
}


export class WalmartCategoryDto extends BaseDto {
  name: string
  category: CategoryDto
  enabled: boolean
  required: string[]
  variantAttributeNames: string[]
  attrs: any[]

  static create() {
    return new WalmartCategoryDto()
  }
}