import * as _ from 'lodash'

import { FileDto, CategoryDto, ProductDetailDto, ProductDto } from '../../../@api/dto'
import { NUMBER_OF_IMAGES, NUMBER_OF_SPECS_IMAGES, DEFAULT_OPTIONS } from './edit-product.constants'

export interface ProductOptionValue {
  name: string
}

export class ProductOption {
  public static COLOR: string = 'Color'

  public name: string
  public values: ProductOptionValue[]

  constructor(name: string = '', values: ProductOptionValue[] = [{name: ''}]) {
    this.name = name
    this.values = values
  }

  get optionName() {
    const matched = DEFAULT_OPTIONS.find(o => o.terms.indexOf(this.name) != -1)
    return matched ? matched.value : this.name
  }
}

export interface ImageHolder {
  title?: string
  loading?: boolean
  image: FileDto
  file?: File
}

export interface ProductHolder {
  product?: ProductDto,
  attributes?: _.Dictionary<ProductOptionValue>
}

export class ProductForm {
  product: ProductDetailDto
  category: CategoryDto
  options: ProductOption[]
  prevProductHolders: ProductHolder[]
  productHolders: ProductHolder[]
  thumbnailHolder: ImageHolder
  colorImageHolders: ImageHolder[]
  imageHolders: ImageHolder[]
  specsImageHolders: ImageHolder[]
  brandId: string

  static create(product: ProductDetailDto = ProductDetailDto.create()) {
    const productHolders = generateProductHolders(product.children)
    const options = generateProductOptions(productHolders, product.options)

    return _.assign<ProductForm>({
      product,
      category: product.category || new CategoryDto(),
      productHolders,
      prevProductHolders: productHolders.slice(0),
      options,
      colorImageHolders: generateColorImageHolders(productHolders),
      imageHolders: generateImageHolders(NUMBER_OF_IMAGES, product.images),
      specsImageHolders: generateImageHolders(NUMBER_OF_SPECS_IMAGES, product.specsImages),
      thumbnailHolder: { image: product.thumbnail }
    })
  }
}

export class CategoryForm {
  selectedCategories: CategoryDto[]
  product: ProductDetailDto
  category: CategoryDto

  static create(product: ProductDetailDto = ProductDetailDto.create()) {
    return _.assign<CategoryForm>({
      selectedCategories: [],
      product,
      category: product.category
    })
  }
}

export const generateColorImageHolders = (productHolders: ProductHolder[], prevColorImageHolders: ImageHolder[] = []): ImageHolder[] => {
  const options = generateProductOptions(productHolders)
  const colorOption = options.find(o => o.name == 'color')

  if (!colorOption) {
    return []
  }

  return colorOption.values.map(v => {
    const prevProductHolder = productHolders.find(o => {
      const value = o.attributes['color']

      if (!value) {
        return false
      }

      return value.name == v.name
    })

    const prevImageHolder = prevColorImageHolders.find(o => o.title == v.name)

    return {
      title: v.name,
      image: prevImageHolder ? prevImageHolder.image : prevProductHolder.product ? prevProductHolder.product.colorThumbnail : null
    }
  })
}

export const generateImageHolders = (count: number, images: FileDto[]): ImageHolder[] => {
  return _.range(count).map(o => ({image: _.get(images, o)}))
}

export const generateProductHolders = (products: ProductDto[]): ProductHolder[] => {
  if (products.length == 1 && _.isEmpty(products[0].productAttributes)) {
    return []
  }

  const productHolders = _.map<ProductDto, ProductHolder>(products, o => {
    return {
      product: o,
      attributes: _.reduce(o.productAttributes, (result, value, key) => {
        result[key] = {name: value}
        return result
      }, {})
    }
  })

  return productHolders
}

export const generateProductHoldersFromOptions = (opts: ProductOption[], current: ProductHolder[] = []) => {
  for(let [index, option] of opts.entries()){
    opts[index].name = _.camelCase(option.name)
  }

  for(let [index, holder] of current.entries()){
    current[index].attributes.name = _.camelCase(holder.attributes.name)
  }

  let holders: ProductHolder[] = []
  let options: ProductOption[] = opts.filter(o => o.name)

  options = options.map(v => {
    const o = _.clone(v)
    o.values = o.values.filter(v => v.name)
    return o
  }).filter(o => o.values.length)

  let cartesian = (options: ProductOption[], n, c) => {
    if(n == options.length) {
      let holder = current.find(o => {
        return _.isEqual(o.product.productAttributes, c)
      })
  
      if (!holder) {
        holder = {
          product: ProductDto.create(c)
        }
      }

      holder.attributes = _.reduce(c, (result, value, key) => {
        result[key] = {name: value}
        return result
      }, {})

      return holders.push(holder)
    }

    let option = options[n]

    option.values.forEach(v => {
      c[option.name] = v.name
      cartesian(options, n + 1, c)
    })
  }

  if (options.length) {
    cartesian(options, 0, {})
  }

  return holders
}

export const generateProductOptions = (holders: ProductHolder[], prevOptions = []) => {
  let options: ProductOption[] = []

  holders.forEach(v => {
    _.keys(v.attributes).forEach(k => {
      let option = options.find(o => o.name == k)
      let value = v.attributes[k]

      if (!option) {
        option = new ProductOption(k, [])
        options.push(option)
      }

      if (option.values.findIndex(o => o.name == value.name) == -1) {
        if (value) option.values.push(value)
      }
    })
  })

  options = _.sortBy(options, o => {
    const index = prevOptions.findIndex(p => p.name == o.name)

    if (index == -1) {
      return 0
    }

    return index
  })

  options.forEach(o => {
    o.values = _.sortBy(o.values, v => {
      const prevOption = prevOptions.find(p => p.name == o.name)

      if (!prevOption) {
        return 0
      }

      return prevOption.values.indexOf(v.name)
    })
  })

  // options.forEach(o => {
  //   o.values = _.sortBy(o.values, o => o.name)
  // })

  return options
}

export const flattenAttributes = (attributes: _.Dictionary<ProductOptionValue>) => {
  return _.map(attributes, (val, key, col) => {
    return {
      name: key,
      value: val.name
    }
  })
}

export const revertFlattenAttributes = (product: ProductDetailDto) => {
  const children = product.children
  
  children.forEach(o => {
    o.productAttributes = o.productAttributes.reduce((prev, current) => {
      prev[current.name] = current.value
      return prev
    }, {})
  })
}
