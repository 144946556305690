import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AdminEbayCategoryDto, AdminEbayProductDto, AdminFindEbayCategoryDto, AdminFindEbayProductDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminEbayService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findProduct(adminFindEbayProductDto: AdminFindEbayProductDto) {
    return await this.httpService.get<PageResultDto<AdminEbayProductDto>>(`/admin/v1/channel/ebay/products`, adminFindEbayProductDto)
  }

  async findOneProduct(id: string) {
    return await this.httpService.get<AdminEbayProductDto>(`/admin/v1/channel/ebay/products/${id}`)
  }

  async findCategory(adminFindEbayCategoryDto: AdminFindEbayCategoryDto) {
    return await this.httpService.get<PageResultDto<AdminEbayCategoryDto>>(`/admin/v1/channel/ebay/categories`, adminFindEbayCategoryDto)
  }

  async findOneCategory(id: string) {
    return await this.httpService.get<AdminEbayCategoryDto>(`/admin/v1/channel/ebay/categories/${id}`)
  }

  async updateCategory(ebayCategoryId: string, categoryId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/channel/ebay/categories/${ebayCategoryId}`, { categoryId })
  }
}
