import { GraphStoreChannel } from "../../../app/+admin/dashboard/dashboard.component.constants"
import { CategoryDto, ProductDto, TenantDto } from "."

export class FindDashboardDto {
  startDate?: Date
  endDate?: Date
}

export class DashboardOverviewDto {
  totalSale: number
  totalOrder: number
  totalItemSold: number
  totalCustomers: number
  totalProduct: number
  totalTenant: number

  static create() {
    return new DashboardOverviewDto()
  }
}

export class DashboardTenantOverviewDto {
  totalTenant: number
  totalSale: number
  totalOrder: number
  totalProduct: number
}

export class DashboardInventoryDto {
  totalInventory: number
  totalLowInventory: number
}

export class DashboardAnalyticsDto {
  totalViewers: number
  totalMaleViewers: number
  totalFemaleViewers: number
  totalUndefinedViewers: number
  categories: CategoryDto[]
  tenants: TenantDto[]
}

export class MerchantDashboardAnalyticsDto {
  totalViewers: number
  totalMaleViewers: number
  totalFemaleViewers: number
  totalUndefinedViewers: number
  categories: CategoryDto[]
  products: ProductDto[]
}


export class GraphDateOrderDto {
  date: string
  orders: number
  label: string
}

export class GraphDateSaleDto {
  date: string
  sales: number
  label: string
}

export class GraphOrderDto {
  previous: GraphDateOrderDto[]
  current: GraphDateOrderDto[]
  totalPrevious: string
  totalCurrent: string
}

export class GraphSaleDto {
  previous: GraphDateSaleDto[]
  current: GraphDateSaleDto[]
  totalPrevious: string
  totalCurrent: string
}

export class DashboardGraphDto {
  orders: GraphOrderDto
  sales: GraphSaleDto
  graphLabels: string[]
}

export class ChartJsDateSet {
  x: number
  y: number
}
export class FindDashboardGraphDto {
  startDate?: string
  endDate?: string
  storeChannel?: GraphStoreChannel
}