import { PagingDto } from './paging.dto'

export class FindUserDto extends PagingDto {
  public searchText?: string
  public roleName?: string
  public dateOfBirth?: Date
  public enabled?: string
  public startDate?: Date
  public endDate?: Date
}
