import { BaseDto } from "./base.dto"

import * as _ from 'lodash'

import { FileDto } from "./file.dto"
import { TenantCategoryDto } from "./tenant-category.dto"
import { PagingDto } from "./paging.dto"
import { TranslationDto } from "./translation.dto"
import { FloorMapDto } from "./floor-map.dto"
import { CategoryDto } from "./category.dto"
import { UserDto } from "./user.dto"
import { ShippingCountryDto } from "./shipping.dto"

export class TenantDto extends BaseDto {
  name?: TranslationDto
  description?: TranslationDto
  email?: string
  paywayTenantId?: string
  telegramGroupId?: string
  logo?: FileDto
  cover?: FileDto
  mobileCover?: FileDto
  slug?: string
  enabled?: boolean
  adminEnabled?: boolean
  category?: TenantCategoryDto
  productCategories?: CategoryDto[]
  contactNumber?: string
  floorMap?: FloorMapDto
  images?: FileDto
  mobileImages?: FileDto
  featuredCreatedAt?: Date
  owners?: UserDto[]
  availableCountries?: ShippingCountryDto[]

  static create(tenant?: TenantDto) {
    return _.assign(new TenantDto(), {
      id: tenant?.id || null,
      name: tenant?.name || TranslationDto.create(),
      paywayTenantId: tenant?.paywayTenantId ?? null,
      telegramGroupId: tenant?.telegramGroupId ?? null,
      description: tenant?.description || TranslationDto.create(),
      mobileCover: tenant?.mobileCover || null,
      category: tenant?.category || null,
      logo: tenant?.logo || null,
      cover: tenant?.cover || null,
      owners: tenant?.owners || []
    })
  }

  static update() {
    return new TenantDto()
  }
}

export class CreateTenantDto {
  name: TranslationDto
  description: TranslationDto
  // telegramGroupId?: string
  // categoryId: string
  // productCategoryIds: string[]
  logoId: string
  coverId: string
  // mobileCoverId: string
}

export class UpdateTenantDto {
  name?: TranslationDto
  description?: TranslationDto
  telegramGroupId?: string
  categoryId?: string
  productCategoryIds?: string[]
  logoId?: string
  coverId?: string
  mobileCoverId?: string
  adminEnabled?: boolean
  contactNumber?: string
}

export class FindTenantDto extends PagingDto {
  searchText?: string
  hasFloorMap?: string
}

export class MerchantUpdateTenantDto {
  name?: TranslationDto
  description?: TranslationDto
  logoId?: string
  coverId?: string
  mobileCoverId?: string
  adminEnabled?: boolean
  contactNumber?: string
  availableCountryCodes?: any[]
  email?: string
}
