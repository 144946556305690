import { BaseDto } from './base.dto'

export class FileDto extends BaseDto {
  public url: string
  public name: string
  public path: string
  public type: string
  public hasChildren: boolean
  public isDirectory: boolean
}

export class ResizeImageDto {
  width: number
  height: number
  resizeType?: string
  moduleImageType: string
}

export enum ModuleImageType {
  HomeBanner = 'HomeBanner',
  HomeMenuIcon = 'HomeMenuIcon',
  HomeTenantCategoryIcon = 'HomeTenantCategoryIcon',
  PromotionThumbnail = 'PromotionThumbnail',
  PromotionBanner = 'PromotionBanner',
  TenantCover = 'TenantCover',
  TenantLogo = 'TenantLogo',
  NewArrivalBanner = 'NewArrivalBanner',
  CategoryBanner = 'CategoryBanner',
  ProductThumbnail = 'ProductThumbnail',
  FloorMapBanner = 'FloorMapBanner',
  ProductImage = 'ProductImage',
  ProductSpecImage = 'ProductSpecImage',
  ReviewImage = 'ReviewImage',
  TenantBanner = 'TenantBanner',
  TenantDetailBanner = 'TenantDetailBanner',
  PickupCounterImage = 'PickupCounterImage',
  StaticPageBanner = 'StaticPageBanner',
  MallBanner = 'MallBanner',
  PromotionDetailBanner = 'PromotionDetailBanner',
  GalleryThumbnail = 'GalleryThumbnail',
  FooterBannerImage = 'FooterBannerImage',
  FooterMobileBannerImage = 'FooterMobileBannerImage'
}