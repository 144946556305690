import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateCategoryDto, FindChildrenCategoryDto, FindCategoryDto, PageResultDto, CategoryDto, UpdateCategoryDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminCategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<CategoryDto[]>(`/admin/v1/categories`)
  }

  async create(createCategoryDto: CreateCategoryDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/categories`, createCategoryDto)
  }

  async findChildren(findChildrenCategoryDto: FindChildrenCategoryDto) {
    return await this.httpService.get<PageResultDto<CategoryDto>>(`/admin/v1/categories/last-children`, findChildrenCategoryDto)
  }

  async findParent(findChildrenCategoryDto: FindChildrenCategoryDto) {
    return await this.httpService.get<PageResultDto<CategoryDto>>(`/admin/v1/categories/parent`, findChildrenCategoryDto)
  }

  async findParentOnly(findChildrenCategoryDto: FindChildrenCategoryDto) {
    return await this.httpService.get<CategoryDto[]>(`/admin/v1/categories/parent-only`, findChildrenCategoryDto)
  }
  
  async update(id: string, updateCategoryDto: UpdateCategoryDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/categories/${id}`, updateCategoryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/categories/${id}`)
  }

  async findOne(id: string) {
    return await this.httpService.get<CategoryDto>(`/admin/v1/categories/${id}`)
  }
}
