import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'

export class SettingDto extends BaseDto {
  id:string
  key: string
  type: string
  value: any
  
  static create() {
    return new SettingDto()
  }
}

export class FindSettingDto {
  
}

export class ScheduleMallExport extends BaseDto {
  id:string
  key: string
  type: string
  value: string | number | boolean
  
  static create() {
    return new ScheduleMallExport()
  }
}