import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CancelWalmartOrderDto, FindWalmartProductDto, PageResultDto, RefundWalmartOrderDto, ShipWalmartOrderDto, WalmartCategoryDto, WalmartProductDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class WalmartService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findProduct(findProductDto: FindWalmartProductDto) {
    return await this.httpService.get<PageResultDto<WalmartProductDto>>(`/merchant/v1/channel/walmart/products`, findProductDto)
  }

  async findCategory(findProductDto: FindWalmartProductDto) {
    return await this.httpService.get<PageResultDto<WalmartCategoryDto>>(`/merchant/v1/channel/walmart/categories`, findProductDto)
  }

  async findOneProduct(id: string) {
    return await this.httpService.get<WalmartProductDto>(`/merchant/v1/channel/walmart/products/${id}`)
  }

  async cancelOrder(cancelOrderDto: CancelWalmartOrderDto) {
    return await this.httpService.post<any>(`/merchant/v1/channel/walmart/orders/cancel`, cancelOrderDto)
  }

  async shipOrder(shipChannelOrderDto: ShipWalmartOrderDto) {
    return await this.httpService.post<any>(`/merchant/v1/channel/walmart/orders/ship`, shipChannelOrderDto)
  }

  async refundOrder(refundWalmartOrderDto: RefundWalmartOrderDto) {
    return await this.httpService.post<any>(`/merchant/v1/channel/walmart/orders/refund`, refundWalmartOrderDto)
  }

  async acknowledgeOrder(orderId: string) {
    return await this.httpService.post<any>(`/merchant/v1/channel/walmart/orders/acknowledge`, { purchaseOrderId: orderId })
  }

  async updateReSync(id: string) {
    return await this.httpService.post<any>(`/merchant/v1/channel/walmart/products/re-sync`, { productId: id })
  }
}
