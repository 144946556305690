import { Injectable } from '@angular/core'

import { CreateCategoryDto, PageResultDto, CategoryDto, UpdateCategoryDto } from '../dto'
import { FindCategoryDto } from '../dto/category.dto'

import { HttpService } from './http.service'


@Injectable()
export class CategoryService {
  constructor(
    private httpService: HttpService
  ) {}

  async find() {
    return await this.httpService.get<CategoryDto[]>('/merchant/v1/categories')
  }

  async findProducts() {
    return await this.httpService.get<CategoryDto[]>('/merchant/v1/categories/products')
  }


  async findLastChildren() {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/merchant/v1/categories/last-children')
  }

  async findChildren(searchText: string) {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/merchant/v1/categories/last-children', {searchText})
  }

  async findWithParent(searchText: string, limit: number = 10, offset: number = 0) {
    return await this.httpService.get<PageResultDto<CategoryDto>>('/merchant/v1/categories/parent', {searchText, limit, offset})
  }

  async findOne(categoryId: string) {
    return await this.httpService.get<CategoryDto>(`/merchant/v1/categories/${categoryId}`)
  }

  async update(categoryId: string, { name, imageId, bannerImageId }: UpdateCategoryDto) {
    return await this.httpService.patch<Boolean>(`/merchant/v1/categories/${categoryId}`, {
      name,
      imageId,
      bannerImageId
    })
  }

  async delete(categoryId: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/categories/${categoryId}`)
  }

  async create({ name, imageId, bannerImageId, parentId  }: CreateCategoryDto) {
    return await this.httpService.post<Boolean>('/merchant/v1/categories', {
      name, 
      imageId, 
      bannerImageId, 
      parentId
    })
  }
}