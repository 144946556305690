

import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageResultDto, ResultDto, ShippingProviderDto, ShippingZoneDto, UpdateShippingProviderDto, UpdateShippingRateDto, UpdateShippingZoneDto } from '../dto'
import { CreateShippingRateDto, CreateShippingWeightDto, FindShippingWeightCountryDto, ShippingCarrierDto, ShippingCountryDto, ShippingRateDto, ShippingWeightDto } from '@api/dto/shipping.dto'
import { PagingDto } from '../dto/paging.dto'

@Injectable()
export class ShippingService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ShippingWeightDto>>('/merchant/v1/shipping/weight', pagingDto)
  }

  async findOne(shippingWeightId: string) {
    return await this.httpService.get<ShippingWeightDto>(`/merchant/v1/shipping/weight/${shippingWeightId}`)
  }

  async update(id: string, shippingWeightDto: CreateShippingWeightDto) {
    return await this.httpService.put<ShippingWeightDto>(`/merchant/v1/shipping/weight/${id}`, shippingWeightDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/shipping/weight/${id}`)
  }

  async findCountries(findShippingWeightCountryDto?: FindShippingWeightCountryDto) {
    return await this.httpService.get<PageResultDto<ShippingCountryDto>>(`/merchant/v1/shipping/countries`, findShippingWeightCountryDto)
  }

  async findAllCountries() {
    return await this.httpService.get<PageResultDto<ShippingCountryDto>>(`/merchant/v1/shipping/countries/all`)
  }

  async findCarrier() {
    return await this.httpService.get<ShippingCarrierDto[]>(`/merchant/v1/shipping/providers`)
  }

  async deleteCountry(id: string, countryName: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/shipping/weight/${id}/rates/countries`, { countryName })
  }

  async create(shippingWeightDto: CreateShippingWeightDto) {
    return await this.httpService.post<ShippingWeightDto>('/merchant/v1/shipping/weight', shippingWeightDto)
  }

  async findOneRate(id: string) {
    return await this.httpService.get<ShippingRateDto>(`/merchant/v1/shipping/weight/${id}/rates`)
  }

  async createRate({ cost, shippingCountries, shippingWeightId }: CreateShippingRateDto) {
    return await this.httpService.post<ShippingRateDto>(`/merchant/v1/shipping/weight/${shippingWeightId}/rates`, { cost, shippingCountries })
  }

  async updateRate(id: string, updateShippingRateDto: UpdateShippingRateDto) {
    return await this.httpService.put<boolean>(`/merchant/v1/shipping/weight/${id}/rates`, updateShippingRateDto)
  }

  async deleteRate(id: string) {
    return await this.httpService.delete<Boolean>(`/merchant/v1/shipping/weight/${id}/rates`)
  }

  async zones(shippingProviderId: string) {
    return await this.httpService.get<ShippingZoneDto[]>(`/merchant/v1/shippings/zones`, { shippingProviderId })
  }

  async zoneDetail(zoneId: string) {
    return await this.httpService.get<ShippingZoneDto>(`/merchant/v1/shippings/zones/${zoneId}`)
  }

  async updateZone(zoneId: string, { enabled, minDays, maxDays }: UpdateShippingZoneDto) {
    return await this.httpService.patch<ResultDto>(`/admin/v1/shippings/zones/${zoneId}`, { enabled, minDays, maxDays })
  }
}
