import { BaseDto } from './base.dto'

import { FileDto } from './file.dto'
import { MallOrderDto } from './mall-order.dto'
import { PagingDto } from './paging.dto'
import { TenantCouponDto } from './tenant-coupon.dto'
import { TenantVoucherDto } from './tenant-voucher.dto'
import { TenantDto } from './tenant.dto'
import { UserDto } from './user.dto'

export enum OrderStatus {
  NEW = 'new',
  COMPLETED = 'completed',
  READY_FOR_PICKUP = 'ready_for_pickup',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  REFUND = 'refund',
  SHIPPING = 'shipping',
  ACCEPTED = 'accepted',
  DROP_OFF = 'drop_off',
  ON_THE_WAY = 'on_the_way',

  //TEMP: JGrab Status
  PENDING = 'pending',
  // NEW = 'new',
  // ACCEPTED = 'accepted',
  DELIVERED = 'delivered',
  // CANCELLED = 'cancelled',
  // REJECTED = 'rejected',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
  REQUEST_CANCEL = 'request_cancel',
  REQUEST_RETURN = 'request_return',
  CANCELLED_BY_STORE = 'cancelled_by_store'
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export enum PaymentMethod {
  COD = 'cod',
  PAY_WAY = 'payway'
}


export class OrderDto extends BaseDto {
  orderNumber: string
  qty: number
  owner: UserDto
  discountTotal: number
  total: number
  paymentStatus: string
  items: OrderItemDto[]
  tenantCouponData: any
  tenantVoucherData: any
  couponTotal: number
  voucherTotal: number
  tenantVoucher: TenantVoucherDto
  tenantCoupon: TenantCouponDto
  tenant: TenantDto
  channel: string
  buyerName: string
}

export class OrderDetailDto extends BaseDto {
  paymentMethod: string
  paymentStatus: string
  channel: string
  status: string
  logs: any
  shippingAddress: any
  reject: any
  cancelled_by_store: any
  cancel: any
  reviewed: boolean
  owner: UserDto
  shippingTotal: number
  trackingNumber: string
  trackingLink: string
  subTotal: number
  total: number
  items: OrderItemDto[]
  orderNumber: string
  channelOrderNumber: string
  discountTotal: number
  voucherTotal: number
  couponTotal: number
  tenantCouponData: any
  tenantVoucherData: any
  shippingCarrier: string
  tenantVoucher: TenantVoucherDto
  tenantCoupon: TenantCouponDto
  mallOrder: MallOrderDto
  tenant: TenantDto
  mallVoucherShareTotal: number
  mallCouponShareTotal: number
  order: any
  buyerName: string
}

export class OrderItemDto {
  quantity: number
  unitPrice: number
  total: number
  subTotal: number
  reviewed: boolean
  unitSalePrice: number
  discountTotal: number
  productDescription: string
  productSlug: string
  productSku: string
  productChannelSku: string
  thumbnail: FileDto
  productEnabled: boolean
  productName: string
  productAttributes: any[]
}

export class CountStatusDto {
  totalOfNew : number  
  totalOfAccepted : number 
  totalOfShipped : number 
  totalOfDelivered : number 
  totalOfRejected : number
  totalOfRequestedCancel : number
  totalOfRequestedReturn : number
  totalOfReturn : number 
  totalOfCancelled : number
  totalOfAllOrders : number 
}

export class FindOrderDto extends PagingDto {
  public searchText?: string
  public status?: string
  public startDate?: string
  public endDate?: string
}

export class OrderBadgeCountDto {
  newCount: number
  completedCount: number
  cancelledCount: number
  totalCount: number
}

export class OrderRejectDto {
  reason: any

  static create() {
    return new OrderRejectDto
  }
}

export class ExportOrderDto {
  statuses?: string[]
  startDate?: Date
  endDate?: Date
  timezone?: string
}

export class ShippingOrderDto {
  trackingNumber?: string
  trackingLink?: string
  shippingCarrier?: string
}