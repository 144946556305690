import { assign } from 'lodash'

import { BaseDto } from './base.dto'

export class ShippingZoneDto extends BaseDto {
  country: string
  minDays: number
  maxDays: number
  enabled: boolean
  provider?: ShippingProviderDto
  rate: ShippingRateDto  
}

export class ShippingProviderDto extends BaseDto {
  name: string
  sortOrder: number

  static create() {
    return new ShippingProviderDto()
  }
}

export class UpdateShippingProviderDto {
  name?: string
}

export class ShippingRateDto extends BaseDto {
  public shippingWeightId?: string
  public cost: number
  public shippingCountries?: ShippingCountryDto[]

  static create() {
    return assign<ShippingRateDto>({
      shippingWeightId: '',
      cost: 0,
      shippingCountries: ShippingCountryDto.create()
    })
  }
}

export class UpdateShippingZoneDto {
  enabled: boolean
  minDays: number
  maxDays: number

  static create({ enabled, minDays, maxDays }: UpdateShippingZoneDto) {
    return assign<UpdateShippingZoneDto>({
      enabled,
      minDays,
      maxDays
    })
  }
}

export class CreateShippingWeightDto {
  minWeight: number
  maxWeight: number
}

export class ShippingWeightDto extends BaseDto {
  minWeight: number
  maxWeight: number
  isMaximum: boolean
  shippingFees: ShippingRateDto[]

  static create() {
    return assign<ShippingWeightDto>({
      minWeight: 0,
      isMaximum: null,
      maxWeight: 0,
      shippingFees: []
    })
  }
}

export class ShippingCarrierDto {
  name: string
  email: string
  phoneNumber: string
}

export class ShippingCountryDto {
  url: string
  alpha3: string
  name: string
  file_url: string
  license: string

  static create() {
    return assign<ShippingCountryDto>({
      url: '',
      alpha3: '',
      name: '',
      file_url: '',
      licenseZ: ''
    })
  }
}

export class CreateShippingRateDto {
  shippingCountries: ShippingCountryDto[]
  cost: number
  shippingWeightId: string
}

export class UpdateShippingRateDto {
  shippingCountries?: ShippingCountryDto[]
  cost?: number
}

export class FindShippingWeightCountryDto {
  shippingWeightId?: string
}