import { Injectable } from "@angular/core"
import { ResultDto } from "@api/dto"

import { HttpService } from "./http.service"

import { MerchantTenantExportDto, MerchantVoucherExportDto } from "../dto/merchant-report.dto"
import { TenantExportCouponDto, TenantExportDiscountCampaignDto, TenantExportLightningDealDto, TenantExportOrderDto, TenantExportSaleDto } from "@api/dto/tenant-report-dto"


@Injectable()
export class MerChantReportSerive {
  constructor(
    private httpService: HttpService
  ) {
  }

  async exportOrder(tenantOrder: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-report', tenantOrder)
  }

  async exportFinancialOrder(tenantOrder: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-financial-report', tenantOrder)
  }

  async exportProductSaleOrder(merchantTenantExoprtDto: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-product-sale-report', merchantTenantExoprtDto)
  }

  async exportProductSaleOrderByTransactionDate(merchantTenantExoprtDto: MerchantTenantExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-product-sale-report-by-transaction-date', merchantTenantExoprtDto)
  }

  async exportVoucher(voucherOrder: MerchantVoucherExportDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/tenant-vouchers/export-report', voucherOrder)
  }

  async exportLightningDealExcel(exportLightningDealDto: TenantExportLightningDealDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/lightning-deals/export-report', exportLightningDealDto)
  }

  async exportDiscountExcel(exportDiscountDto: TenantExportDiscountCampaignDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/product-discount/export-report', exportDiscountDto)
  }

  async exportCouponExcel(exportCouponDto: TenantExportCouponDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/coupons/export-report', exportCouponDto)
  }

  async exportOrderExcel(exportOrderDto: TenantExportOrderDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-report/order', exportOrderDto)
  }

  async exportSaleExcel(exportSaleDto: TenantExportSaleDto) {
    return await this.httpService.get<ResultDto>('/merchant/v1/orders/export-report/sale', exportSaleDto)
  }

}

