import { Injectable } from '@angular/core'

import { CreateProductDiscountDto, FindProductDiscountDto, PageResultDto, ProductDiscountDto, UpdateProductDiscountDto } from '@api/dto'

import { HttpService } from './http.service'
import { PagingDto } from '@api/dto/paging.dto'
import { AdminLightningDealDto, CreateLightningDealDto, FindLightningDealDto } from '@api/dto/admin-lightning-deal.dto'

@Injectable()
export class AdminLightningDealService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findLightningDealDto: FindLightningDealDto) {
    return await this.httpService.get<PageResultDto<AdminLightningDealDto>>(`/admin/v1/lightning-deal`, findLightningDealDto)
  }

  async findProducts(id: string, merchantId: string, { limit, offset }: PagingDto) {
    return await this.httpService.get<AdminLightningDealDto>(`/admin/v1/lightning-deal/products/${id}/${merchantId}`, { limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminLightningDealDto>(`/admin/v1/lightning-deal/${id}`)
  }

  async create(createLightningDeal: CreateLightningDealDto) {
    return await this.httpService.post<ProductDiscountDto>(`/admin/v1/lightning-deal`, createLightningDeal)
  }

  async update(id: string, updateDiscountProductCollectionDto: UpdateProductDiscountDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/lightning-deal/${id}`, updateDiscountProductCollectionDto)
  }

  // async updateItems(id: string, productIds: string[]) {
  //   return await this.httpService.post<Boolean>(`/merchant/v1/product-discount/items`, { productDiscountId: id, productIds })
  // }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/lightning-deal/${id}`)
  }

  // async deleteItem(id: string, productId: string) {
  //   return await this.httpService.delete<Boolean>(`/merchant/v1/product-discount/${id}/items/${productId}`)
  // }
}