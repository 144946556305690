import { BaseDto } from "./base.dto"
import { CategoryDto } from "./category.dto"

import { PagingDto } from "./paging.dto"
import { ProductDto } from "./product.dto"

export class AdminWalmartProductDto extends BaseDto {
  product: ProductDto
  status: string
  channel: string
  response: any
  walmartFeedId: string
  sku: string
  channelSku: string
  ingestionErrors: any
  validationErrors: any

  static create() {
    return new AdminWalmartProductDto()
  }
}

export class AdminWalmartCategoryDto extends BaseDto {
  name: string
  category: CategoryDto
  enabled: boolean
  required: string[]
  variantAttributeNames: string[]
  attrs: any[]

  static create() {
    return new AdminWalmartCategoryDto()
  }
}

export class AdminFindWalmartProductDto extends PagingDto {
  searchText?: string
}

export class AdminFindWalmartCategoryDto extends PagingDto {
  searchText?: string
  categoryId?: string
  walmartCategoryId?: string
}

export enum WalmartProductStatus {
  TRANSMIT = 'TRANSMIT',
  DATA_FIX = 'DATA_FIX',
  INGESTION_IN_PROGRESS = 'INGESTION_IN_PROGRESS',
  PUBLISH_IN_PROGRESS = 'PUBLISH_IN_PROGRESS',
  PUBLISHED = 'PUBLISHED',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  UNPUBLISHED = 'UNPUBLISHED',
  DATA_FIX_TICKET = 'DATA_FIX_TICKET',
  SYSTEM_ERROR_TICKET = 'SYSTEM_ERROR_TICKET',
  NOT_FOUND = 'NOT_FOUND',
  FEED_RESPONSE_ITEMS_EMPTY = 'FEED_RESPONSE_ITEMS_EMPTY',
}

export enum WalmartFeedStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export enum FeedStatus {
  PROCESSED = 'PROCESSED'
}

export class AdminFindWalmartFeedDto extends PagingDto {
  searchText?: string
}

export class AdminWalmartFeedDto extends BaseDto {
  walmartFeedId: string
  walmartFeedStatus: string
  walmartFeedSubmissionDate: Date
  walmartFeedLimit: number
  walmartFeedOffset: number
  request: any
  response: any
  status: string
}

export enum WalmartOrderStatus {
  Created = 'Created',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  Refund = 'Refund',
  Acknowledged = 'Acknowledged'
}