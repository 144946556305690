import { BaseDto } from "./base.dto"
import { PagingDto } from "./paging.dto"

import { ProductDto } from "./product.dto"

export class EbayProductDto extends BaseDto {
  product: ProductDto
  status: string
  channel: string
  response: any
  sku: string
  channelSku: string
  request: any
  errors: any
  ebayOfferId: string
  gtin: string
  ebayFeedId: string
  ebayListingId: string

  static create() {
    return new EbayProductDto()
  }
}

export class FindEbayProductDto extends PagingDto {
  searchText?: string
  statuses?: string[]
}

export class ShipEbayOrderDto {
  orderNumber: string
  trackingNumber: string
  shippingCarrierCode: string
}

export class CancelEbayOrderDto {
  comment: string
  reason: string
  orderNumber: string
}