import { Injectable } from "@angular/core"

import { CreatePromotionDto, FindPromotionDto, PageResultDto, PromotionDetailDto, PromotionDto, UpdatePromotionDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class AdminPromotionService {
  constructor(
    private httpService: HttpService){
  }

  async find({ limit, offset, searchText }: FindPromotionDto) {
    return await this.httpService.get<PageResultDto<PromotionDto>>('/admin/v1/promotions', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<PromotionDetailDto>(`/admin/v1/promotions/${id}`)
  }

  async create(createPromotionDto: CreatePromotionDto) {
    return await this.httpService.post<PromotionDto>('/admin/v1/promotions', createPromotionDto)
  }

  async update(id: string, updatePromotionDto: UpdatePromotionDto) {
    return await this.httpService.put<boolean>(`/admin/v1/promotions/${id}`, updatePromotionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/promotions/${id}`)
  }

  async updateThumbnail(id: string, fileId: string) {
    return await this.httpService.put<boolean>(`/admin/v1/promotions/${id}/thumbnail`, { fileId })
  }

  async sendNotification(id: string) {
    return await this.httpService.post<boolean>(`/admin/v1/promotions/${id}/notification`)
  }

  async publishNewsletter(id: string) {
    return await this.httpService.post<boolean>(`/admin/v1/promotions/${id}/newsletter`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/admin/v1/promotions/${id}/enable`)
    }

    return await this.httpService.put<Boolean>(`/admin/v1/promotions/${id}/disable`)
  }
}