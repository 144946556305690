import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { HomeSectionTitleUpdateDto, HomeSectionTitleParamKeyDto } from '../dto/home-section-title.dto'

@Injectable()
export class AdminHomeSectionTitleService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<HomeSectionTitleParamKeyDto[]>(`/admin/v1/home-section`)
  }

  async findOne(key: string) {
    return await this.httpService.get<HomeSectionTitleUpdateDto>(`/admin/v1/home-section/${key}`)
  }

  async update(key: string, { title }: HomeSectionTitleUpdateDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/home-section/${key}`, {title})
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/admin/v1/home-section/${id}/enabled`)
    }

    return await this.httpService.put<Boolean>(`/admin/v1/home-section/${id}/disabled`)
  }
}
