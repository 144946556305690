import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { AdminDeliveryListDto, DeliveryListDto } from '../dto/delivery-list.dto'
import { CountStatusDto, ExportOrderDto, OrderDetailDto, OrderDto, OrderRejectDto, ShippingOrderDto } from '../dto/order.dto'
import { FindOrderDto, MallOrderDetailDto, MallOrderDto, PageResultDto, ResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'


@Injectable()
export class AdminOrderService {
  constructor(
    private httpService: HttpService
  ) {}

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/v1/orders', findOrderDto)
  }

  async countStatus() {
    return await this.httpService.get<CountStatusDto>('/admin/v1/orders/count')
  }

  async exportExcel(dateFilterDto: ExportOrderDto) {
    return await this.httpService.get<any>(`/admin/v1/orders/export`, dateFilterDto)
  }

  async findOne(tenantOrderNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/admin/v1/orders/${tenantOrderNumber}`)
  }

  async accept(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/accept`)
  }

  async complete(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/complete`)
  }

  async shipping(tenantOrderNumber: string, shippingOrderDto: ShippingOrderDto) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/ship`, shippingOrderDto)
  }

  async reject(tenantOrderNumber: string, { reason }: OrderRejectDto) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/reject`, { reason })
  }

  async readyPickup(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/ready-pickup`)
  }

  async addTrackingNumber(orderId: string, trackingNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${orderId}/trackingNumber`, { trackingNumber })
  }

  // async cancel(tenantOrderNumber: string) {
  //   return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/cancel`)
  // }

  async return(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/return`)
  }

  async delivered(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/delivered`)
  }

  async acceptCancelRequest(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/accept-cancel-request`)
  }

  async rejectCancelRequest(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${tenantOrderNumber}/reject-cancel-request`)
  }
}