import { BaseDto } from "./base.dto"

import { DateOfBirthDto } from "./date-of-birth.dto"
import { PagingDto } from "./paging.dto"
import { ProductDto } from "./product.dto"
import { TenantDto } from "./tenant.dto"

export class FindProductDiscountTenantDto extends PagingDto {
  status?: string
}

export class ProductDiscountTenantDto extends BaseDto {
  tenant: TenantDto
  title: string
  isSortOrder: boolean
  discount: number
  discountType: string
  expiredAt: Date
}

export class FindProductDiscountDto extends PagingDto {
  status?: string
}

export class AdminProductDiscountDto extends BaseDto {
  title: string
  startedAt: Date
  expiredAt: Date
  discount: number
  discountType: string
  enabled: boolean
  tenant: TenantDto
  status: string
  url: string
  items: ProductDto[]

  static create() {
    return new AdminProductDiscountDto()
  }
}

export class ProductDiscountDto extends BaseDto {
  title: string
  startedAt: DateOfBirthDto
  expiredAt: DateOfBirthDto
  discount: number
  discountType: string
  enabled: boolean
  tenant: TenantDto
  status: string
  url: string
  items: ProductDto[]

  static create() {
    return new ProductDiscountDto()
  }
}

export class CreateProductDiscountDto {
  title: string
  startedAt?: DateOfBirthDto
  expiredAt?: DateOfBirthDto
  discount?: number
  discountType?: string
}

export class UpdateProductDiscountDto {
  title?: string
  startedAt?: DateOfBirthDto
  expiredAt?: DateOfBirthDto
  discount?: number
  discountType?: string
  enabled?: boolean
}

export class CreateProductDiscountItemDto {
  productDiscountId: string
  productId: string
}