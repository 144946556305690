import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AdminFindWalmartCategoryDto, AdminFindWalmartProductDto, AdminWalmartCategoryDto, AdminWalmartFeedDto, AdminWalmartProductDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class AdminWalmartService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findProduct(adminFindChannelProductDto: AdminFindWalmartProductDto) {
    return await this.httpService.get<PageResultDto<AdminWalmartProductDto>>(`/admin/v1/channel/walmart/products`, adminFindChannelProductDto)
  }

  async findCategory(adminFindChannelProductDto: AdminFindWalmartCategoryDto) {
    return await this.httpService.get<PageResultDto<AdminWalmartCategoryDto>>(`/admin/v1/channel/walmart/categories`, adminFindChannelProductDto)
  }

  async findOneCategory(id: string) {
    return await this.httpService.get<AdminWalmartCategoryDto>(`/admin/v1/channel/walmart/categories/${id}`)
  }

  async updateCategory(categoryId: string, walmartCategoryId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/channel/walmart/categories/${walmartCategoryId}`, { categoryId })
  }

  async findOneProduct(id: string) {
    return await this.httpService.get<AdminWalmartProductDto>(`/admin/v1/channel/walmart/products/${id}`)
  }

  async findFeed(adminFindChannelProductDto: AdminFindWalmartProductDto) {
    return await this.httpService.get<PageResultDto<AdminWalmartFeedDto>>(`/admin/v1/channel/walmart/feeds`, adminFindChannelProductDto)
  }

  async findOneFeed(id: string) {
    return await this.httpService.get<AdminWalmartFeedDto>(`/admin/v1/channel/walmart/feeds/${id}`)
  }
}
