import { Injectable } from '@angular/core'

import * as _ from 'lodash'
import { DashboardAnalyticsDto, DashboardOverviewDto, DashboardTenantOverviewDto, FindDashboardDto, ProductDto } from '../dto'

import { HttpService } from './http.service'
import { DashboardGraphDto, FindDashboardGraphDto } from '@api/dto/dashboard.dto'

@Injectable()
export class AdminDashboardService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findDashboardDto: FindDashboardDto) {
    return await this.httpService.get<DashboardOverviewDto>(`/admin/v1/dashboard/overview`, findDashboardDto)
  }

  async findAll({ startDate, endDate }: FindDashboardDto) {
    return await this.httpService.get<DashboardOverviewDto>(`/admin/v1/dashboard/all-overview`, { startDate, endDate })
  }

  async findTenant(findDashboardDto: FindDashboardDto) {
    return await this.httpService.get<DashboardTenantOverviewDto>(`/admin/v1/dashboard/tenant-overview`, findDashboardDto)
  }

  async findTopSale(findDashboardDto: FindDashboardDto) {
    return await this.httpService.get<ProductDto[]>(`/admin/v1/dashboard/sale-products`, findDashboardDto)
  }

  async findAnalytics(findDashboardDto: FindDashboardDto) {
    return await this.httpService.get<DashboardAnalyticsDto>(`/admin/v1/dashboard/mall-analytics`, findDashboardDto)
  }

  async findGraph(findDashboardGraphDto: FindDashboardGraphDto){
    return await this.httpService.get<DashboardGraphDto>('/admin/v1/dashboard/graph', findDashboardGraphDto)
  }
}
