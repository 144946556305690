import { CategoryDto, TenantDto } from "."
import { BaseDto } from "./base.dto"

import { PagingDto } from "./paging.dto"
import { ProductDto } from "./product.dto"

export class AdminEbayProductDto extends BaseDto {
  sku: string
  channelSku: string
  product: ProductDto
  tenant: TenantDto
  response: any
  ebayOfferId: string
  request: any
  errors: any
  gtin: string
  ebayFeedId: string
  ebayListingId: string
}

export class AdminFindEbayProductDto extends PagingDto {
  searchText?: string
}

export class AdminFindEbayCategoryDto extends PagingDto {
  searchText?: string
  categoryId?: string
  ebayCategoryId?: string
}

export class AdminEbayCategoryDto extends BaseDto {
  name: string
  category: CategoryDto
  enabled: boolean
  required: string[]
  variantAttributeNames: string[]
  attrs: any[]
  ebayCategoryId: string
  searchPath: string
  paths: string[]

  static create() {
    return new AdminEbayCategoryDto()
  }
}

export enum EbayProductStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  TRANSMIT = 'TRANSMIT',
  DATA_FIX = 'DATA_FIX',
  UPDATE_UNPUBLISHED_OFFER_IN_PROGRESS = 'UPDATE_UNPUBLISHED_OFFER_IN_PROGRESS',
  UPDATE_PUBLISHED_OFFER_IN_PROGRESS = 'UPDATE_PUBLISHED_OFFER_IN_PROGRESS',
  CREATE_OFFER_IN_PROGRESS = 'CREATE_OFFER_IN_PROGRESS',
  PUBLISH_IN_PROGRESS = 'PUBLISH_IN_PROGRESS'
}