import * as _ from 'lodash'
import { assign } from 'lodash'
export class AdminWhitelistPaywayMidDto {
  tenantId: string
  mid: string

  static create({ tenantId, mid}) {
    return _.assign(new AdminWhitelistPaywayMidDto(), {
      tenantId,
      mid
    })
  }
}