export class TranslationDto<T = string> {
  en: T
  ja: T

  public static create(en?: any, ja? : any) {
    const translation = new TranslationDto()

    translation.en = en || ''
    translation.ja = ja || ''

    return translation
  }
}
